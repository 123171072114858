/* eslint-disable */

export const makeSpiral = () => {
  var width = window.innerWidth,
    height = window.innerHeight;
  // var width = 100,
  // height = 100;

  var centerX = width / 2,
    centerY = height / 2,
    radius = -centerY,
    // // sides = 100,
    // sides = 220,
    // coils = 30,
    // rotation = 0.25;
    coils = 9,
    sides = 500,
    // radius = 10,
    rotation = 0,
    dropRatio = 0;

  // How far to step away from center for each side.
  var awayStep = radius / sides;
  // How far to rotate around center for each side.
  var aroundStep = coils / sides; // 0 to 1 based.
  // Convert aroundStep to radians.
  var aroundRadians = aroundStep * 2 * Math.PI;
  // Convert rotation to radians.
  rotation *= 2 * Math.PI;

  var new_time = [];

  // For every side, step around and away from center.
  for (var i = 1; i <= sides; i++) {
    // How far away from center
    var away = i * awayStep;

    // How far around the center.
    var around = i * aroundRadians + rotation;

    // Convert 'around' and 'away' to X and Y.
    var x = centerX + Math.cos(around) * away;
    var y = centerY + Math.sin(around) * away;

    // const dotDelay = 1200;
    const dotDelay = 0;

    // const dropRatio = 0.1;
    const delayMultipilier = 31;
    // const delayMultipilier = 1;

    if (
      (i < 100 && i % 3 === 0 && true) ||
      i < 2 ||
      i == 7 ||
      i == 10 ||
      (i < 20
        ? // ? i % 5 === 0 && Math.random() > dropRatio
          false
        : Math.random() > dropRatio)
    ) {
      new_time.push({
        x: x,
        y: y,
        delay: (i > 1 ? dotDelay : 0) + i * delayMultipilier,
      });
    }
  }
  return new_time;
};
