/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import { makeSpiral } from '@/components/makeSiral';

const threshold = 90;

const inRange = (mousePos, { x, y }) => {
  return (
    mousePos.x - x < threshold &&
    mousePos.x - x > -threshold &&
    mousePos.y - y < threshold &&
    mousePos.y - y > -threshold
  );
};

const getRangeMagnitude = (mousePos, { x, y }) => {
  if (!inRange(mousePos, { x, y })) {
    return 1;
  }
  const xMagnitudeFromCenter = Math.abs(((x - mousePos.x) / threshold) * 0.9);
  const yMagnitudeFromCenter = Math.abs((y - mousePos.y) / threshold) * 0.9;

  return xMagnitudeFromCenter * yMagnitudeFromCenter;
};
export const ComingSoonCanvas = (props) => {
  if (typeof window === 'undefined') {
    return null;
  }
  const [points, setPoints] = useState([]);
  const width = window.innerWidth;
  const height = window.innerHeight;

  const canvasRef = useRef(null);
  const mousePos = useRef({ x: 100, y: 100 });
  // eslint-disable-next-line no-return-assign
  const setMousePos = (pos) => (mousePos.current = pos);

  const draw = (ctx, frameCount, mousePosition) => {
    const speedModifier = mousePosition.y / window.innerHeight / 40 + 0.005;
    const sizeModifier = mousePosition.x / window.innerWidth;

    const getR = (mouse, { x, y }, i, isInRange) => {
      const baseR = 5;
      if (!mouse) {
        return baseR;
      }
      const rangeModifier = isInRange
        ? 14 * getRangeMagnitude(mousePosition, { x, y }) ** 8
        : 0;

      const distanceModifier = (i * i * i) / 10000000;

      return (
        2 * Math.sin((-frameCount + i) * speedModifier) ** 2 +
        1 +
        2 * sizeModifier +
        rangeModifier +
        distanceModifier
      );
    };
    ctx.clearRect(0, 0, width, height);
    points.forEach((p, i) => {
      const isInRange = inRange(mousePosition, p);
      // if (isInRange && frameCount % 100 === 0) {
      //   console.log(
      //     '_______ getRangeMagnitude(mousePosition, p)',
      //     getRangeMagnitude(mousePosition, p),
      //   );
      // }
      ctx.fillStyle = `rgba(0,0,0, ${
        !isInRange ? 0.3 : getRangeMagnitude(mousePosition, p) * 0.3
      })`;
      ctx.beginPath();
      if (i < (frameCount * frameCount) / 20) {
        ctx.arc(
          p.x + 10,
          p.y - 3,
          getR(mousePosition, p, i, isInRange),
          7,
          0,
          2 * Math.PI,
        );
        ctx.fill();
        // ctx.stroke();
      }
    });
  };

  useEffect(() => {
    if (!window) {
      return;
    }

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    let frameCount = 0;
    let animationFrameId;

    //Our draw came here
    const render = () => {
      // eslint-disable-next-line no-plusplus
      frameCount++;
      draw(context, frameCount, mousePos.current);
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    // eslint-disable-next-line consistent-return
    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw, points]);

  useEffect(() => {
    setPoints(makeSpiral());
  }, []);

  const onMouseMove = (e) => {
    var rect = canvasRef.current.getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    var y = e.clientY + rect.top; //y position within the element.
    setMousePos({ x, y });
  };
  return (
    <canvas
      ref={canvasRef}
      {...props}
      width={window.innerWidth}
      height={window.innerHeight}
      onMouseMove={onMouseMove}
    />
  );
};

export default ComingSoonCanvas;
