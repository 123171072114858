import * as React from 'react';
import styled from 'styled-components';

export const ComingSoonText: React.FC = () => (
  <Center>
    <Background>
      <Heading>
        <Skalar>skalar</Skalar>
        <Dot />
        <br />
        <Studio>studio</Studio>
      </Heading>
      <Subheading>
        Coming
        <br />
        soon!
      </Subheading>
    </Background>
  </Center>
);

export const Center = styled.div`
  pointer-events: none;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
export const Dot = styled.span`
  width: 10px;
  height: 10px !important;
  border-radius: 10px;
  background: black;
  display: inline-block;
  margin: auto -3px;
`;
export const Background = styled.div``;
export const Heading = styled.h1`
  pointer-events: none;

  margin: 0;
  font-size: 5em;
  line-height: 0.7em;
  position: absolute;
  top: calc(50vh - 0.7em + 2px);
  right: calc(50vw - 18px);
`;
export const Skalar = styled.span`
  opacity: 0;
  animation-delay: 0.3s;
  animation: appear 0.7s forwards;
`;
export const Studio = styled.span`
  //letter-spacing: 2.6px;
  opacity: 0;
  animation: appear 0.7s forwards;
  //animation-delay: 3s;
  animation-delay: 0.5s;
`;
export const Subheading = styled.span`
  animation: appear 0.7s forwards;
  opacity: 0;
  animation-delay: 1.5s;

  position: absolute;
  top: calc(50vh + 60px);
  left: calc(50vw + 20px);
`;
