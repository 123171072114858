import React from 'react';
import { PageProps } from 'gatsby';
import ComingSoonCanvas from '@/components/ComingSoonCanvas';
import { ComingSoonText } from '@/components/ComingSoonText';
import '../components/fonts.css';
import '../components/main.css';

const ComingSoon: React.FC<PageProps> = () => (
  <>
    <ComingSoonText />
    <ComingSoonCanvas />
  </>
);

export default ComingSoon;
